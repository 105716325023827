import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Back Squat 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`“Nasty Girls V2”`}</em></strong></p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`50 Pistols, alternating`}</p>
    <p>{`7 Muscle Ups`}</p>
    <p>{`10 Hang Power Cleans (175/115)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today we will have a Mobility for Athletic Performance workshop
from 1:45-3:00pm.  This workshop will be taught by Stephanie Bellissimo
and will focus on floor work and locomotion for CrossFit athletes.  This
intro class is free to all CrossFit the Ville members and just \\$15 for
non members.  The 12:30 CrossFit class will end promptly at 1:30 in
order to start the Mobility workshop on time. There will be no open gym
after class.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      